export type ErrorProps = {
  statusCode: number
  title: string
  subheader: string
  buttonText: string
}

export const defaultErrorProps: Omit<ErrorProps, 'statusCode'> = {
  title: 'Hoppsan! Det verkar som att något gick fel...',
  subheader: 'Det verkar som att vi inte kunde hitta sidan du letade efter',
  buttonText: 'Gå tillbaka till startsidan',
}
